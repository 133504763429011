import { Link, isRouteErrorResponse, useRouteError } from "@remix-run/react";
import type { InputHTMLAttributes } from "react";

/**
 * Styled text input
 *
 * Does not look good for radio buttons, checkboxes, etc.
 */
export const StyledTextInput = (
  props: InputHTMLAttributes<HTMLInputElement>,
) => (
  <input
    className="block w-full max-w-lg rounded border border-slate-300 text-black shadow-sm
    disabled:border-transparent disabled:bg-slate-200 disabled:shadow-none"
    {...props}
  />
);

interface DisableLinkProps {
  /** Whether the link should be disabled. Will turn the anchor into a button */
  disabled?: boolean;
  // All other props
  [x: string]: any;
}

/**
 * Component that renders a link that can be disabled.
 *
 * Will turn into a button if `disabled` is true.
 */
export const DisableLink = ({
  disabled = false,
  ...props
}: DisableLinkProps) => {
  if (disabled) {
    return <button {...props} disabled />;
  }
  return (
    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
    <a {...props} target="_blank" rel="noopener noreferrer" />
  );
};

// The "CatchBoundary" (now has to be embedded in a ErrorBoundary, can't be used directly)
export function CatchBoundary({ error }: { error: any }) {
  return (
    <div className="flex items-center justify-center p-6">
      <div className="space-y-2 rounded-lg border-2 shadow-lg bg-white p-6 text-center text-red-900">
        <h2 className="text-5xl">{error.status || "There was a Problem"}</h2>
        <p>{error.data?.message || error.data?.error || undefined}</p>
        <div className="p-4">
          <Link to="/" className="text-blue-500 bg-slate-100 rounded p-2">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export function DefaultErrorBoundary() {
  const error = useRouteError();

  console.error(error);

  if (isRouteErrorResponse(error)) {
    return <CatchBoundary error={error} />;
  }

  throw error;
}
